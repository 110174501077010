
/**
* Кастомный хук, который хранит в себе ссылки на сторонние ресурсы
*
* @returns Объект со ссылками 
* */
export function useLinks() {
  return {
    vkLink: "https://vk.com/wecheck",
    licenceLink: "https://www.web.wecheck.ru/licence",
    productionHomeLink: "https://go.wecheck.ru/",
    marketplaceLink: "/",
    blogLink: "https://wecheck.ru/blog/",
    advertisingLink: "https://go.wecheck.ru/promotion-position",
    parsingLink: "https://www.web.wecheck.ru/parsing",
    competitorsLink: "https://www.web.wecheck.ru/competitors",
  }
} 
