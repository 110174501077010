import React, { useState } from "react";

import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as Wecheck } from "../../assets/svg/wecheck.svg";

import Menu from "../../assets/img/menu.png";
import {
  StyledNav,
  StyledLogoContainer,
  StyledMenuGroup,
  StyledBurgerMenu,
  StyledBurgerButton,
  StyledLogButton,
  StyledHeaderContainer,
  StyledHeader,
  StyledCloseButton, StyledBurgerMenuHeader, StyledMenuContent,
} from "./HeaderStyle";
import {NavButton, GreenButton, /*PhoneChatsHeader,*/ PhoneChatsMenu} from "../../elements";
import { ReactDimmer } from "react-dimmer";
import { NavLink } from "../NavLink/NavLink";
import { useLocation } from "react-router-dom";
import ym from "react-yandex-metrika";
import gtag from "ga-gtag";

import { useLinks } from "../../hooks"

export const HeaderComponent = ({ activeTab }) => {
  const {
    // competitorsLink,
    blogLink,
    // parsingLink,
    productionHomeLink,
    marketplaceLink,
    advertisingLink,
  } = useLinks()

  const [isMenuOpen, setMenu] = useState(false);
  const location = useLocation();
  const isEcomexpo = location.pathname === "/ecomexpo";
  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  return (
    <StyledHeader>
      <StyledHeaderContainer isEcomexpo={isEcomexpo}>
        <StyledNav>
          <div style={{display: 'flex'}}>
          <StyledLogoContainer>
            <Logo style={{ marginRight: "17px" }} />
            <Wecheck />
          </StyledLogoContainer>
          <StyledMenuGroup>
            <div style={{ display: 'inline-flex' }}>
            <NavLink link={marketplaceLink} active={activeTab === 'market'} noMargin onClick={() => {
              window.scrollTo(0, 0)
            }}>Маркетплейсы</NavLink>
            <div style={{ width: '12px', borderBottom: '1px solid #EAEAEA'}}/>
            <NavLink link={'#rate_section'} active={activeTab !== 'market'} isA >Тарифы</NavLink>
            </div>
            {/*<NavButton active={false} link={parsingLink}>*/}
            {/*  Парсинг*/}
            {/*</NavButton>*/}
            {/*<NavButton*/}
            {/*  active={false}*/}
            {/*  link={competitorsLink}*/}
            {/*>*/}
            {/*  Конкуренты*/}
            {/*</NavButton>*/}
            <NavButton
              active={false}
              link={advertisingLink}
            >
              Рекламные ставки
            </NavButton>
            <NavButton
              active={false}
              link={blogLink}
            >
              Блог
            </NavButton>
            {/* <NavLink>
              <p>Документы</p> и законодательство
            </NavLink> */}
          </StyledMenuGroup>
          </div>
          <div style={{ display: 'flex' }}>
            {/*<PhoneChatsHeader />*/}
            <StyledLogButton>
              <GreenButton
                tag={() => ym("reachGoal", "button_voiti")}
                tag2={() =>
                  gtag("event", "click_button", {
                    button: "vxod",
                  })
                }
                link={productionHomeLink}
              >
                Войти
              </GreenButton>
            </StyledLogButton>

          <StyledBurgerButton onClick={handleMenu}>
            <img src={Menu} alt="no img"></img>
          </StyledBurgerButton>
          <ReactDimmer
            isOpen={isMenuOpen}
            exitDimmer={setMenu}
            zIndex={100}
            blur={1.5}
          />
          </div>
        </StyledNav>
      </StyledHeaderContainer>
      <StyledBurgerMenu className={`app-menu ${isMenuOpen ? "menu-open" : ""}`}>
        <StyledBurgerMenuHeader>
          <h2 style={{ textAlign: "center", marginBottom: 0 }}>Меню</h2>
          <StyledCloseButton onClick={() => setMenu(false)}></StyledCloseButton>
        </StyledBurgerMenuHeader>
        <StyledMenuContent>
          <NavButton link={marketplaceLink} style={{ fontWeight: 600 }}>Маркетплейсы</NavButton>
          {/*<NavButton link={parsingLink}>Парсинг</NavButton>*/}
          {/*<NavButton link={competitorsLink}>Конкуренты</NavButton>*/}
          <NavButton link={advertisingLink}>Рекламные ставки</NavButton>
          <NavButton link={blogLink}>Блог</NavButton>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50}}>
            <PhoneChatsMenu />
            <GreenButton
              tag={() => ym("reachGoal", "button_voiti")}
              link={productionHomeLink}
            >
              Войти
            </GreenButton>
          </div>
        </StyledMenuContent>
      </StyledBurgerMenu>
    </StyledHeader>
  );
};
