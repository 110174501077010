import React, {useEffect, useMemo, useRef, useState} from "react";
import { Container } from "../../elements";
import {
  StyledHeader,
  StyledSection,
  StyledTitle,
  StyledButtonGroup,
  StyledRateBlock,
} from "./RateSectionStyle";
import { RateButton, RateItem } from "../../elements";
import {
  detailArrayBasic,
  detailArrayEnterprise, detailArrayExtWB,
} from "./RateSectionConstant";
import ym from "react-yandex-metrika";
import gtag from "ga-gtag";
import {ReactComponent as ChromeIcon} from "../../assets/svg/chrome.svg";
import {ReactComponent as YandexIcon} from "../../assets/svg/yandex.svg";
import TimerIcon from "../../assets/icons/TimerIcon";
import WarningIcon from "../../assets/icons/WarningIcon";
import LikeIcon from "../../assets/icons/LikeIcon";

export const RateSection = ({ rateRef }) => {
  const monthsOne = "one-month"
  const monthsSix = "six-months"
  const monthsThree = "three-months"

  const [isActive, setIsActive] = useState(monthsSix);
  const [mobileActiveTariff, setMobileActiveTariff] = useState('pro');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const swipeRef = useRef();
  const periodValues = {
    [monthsOne]: [3900, 4900, 790],
    [monthsThree]: [3300, 4300, 690],
    [monthsSix]: [2900, 3900, 590],
  };

  const handleChangeScroll = () => {
    if (swipeRef.current) {
      const children = swipeRef.current.childNodes;
      let child = 0;
      children.forEach(el => {
        if (el.id === mobileActiveTariff) {
          child = el.offsetLeft;
        }
      })
      swipeRef.current.scrollLeft = child - 16;
    }
  }

  useEffect(() => {
    handleChangeScroll();
  }, [mobileActiveTariff])
  const handleRatePeriod = (periodName) => {
    if (periodName === monthsOne) {
      gtag("event", "click_button", {
        button: "button_months",
      });
      ym("reachGoal", "button_click_months");
    }
    if (periodName === monthsThree) {
      gtag("event", "click_button", {
        button: "button_3_months",
      });
      ym("reachGoal", "button_click_3_months");
    }
    if (periodName === monthsSix) {
      gtag("event", "click_button", {
        button: "buttoт_6_months",
      });
      ym("reachGoal", "button_click_6_months");
    }

    setIsActive(periodName);
  };

  const computedRateItemDescription = useMemo(() => {
    if (isActive === monthsOne) return "В месяц"
    if (isActive === monthsThree) return "при оплате за 3 месяца"

    return "при оплате за 6 месяцев"
  }, [isActive, monthsOne, monthsThree])

  const basicTariff = <RateItem
      id="basic"
      title="Basic"
      price={periodValues[isActive][0]}
      period={
        <div className="flex space-x-3 items-center justify-center">
          <TimerIcon width="21" height="21" />
          <div className="flex space-x-1 text-base">
                <span className="block text-[#6E6E6E]">
                  Данные
                </span>
            <span className="block text-black">
                  за 30 дней
                </span>
          </div>
        </div>
      }
      expandedKeys={expandedKeys}
      setExpandedKeys={setExpandedKeys}
      detailArray={detailArrayBasic}
      previusPrice={isActive !== monthsOne ? periodValues[monthsOne][0] : ""}
      description={computedRateItemDescription}
  />

  const proTariff = <RateItem
      id="pro"
      recommend
      title="PRO"
      price={periodValues[isActive][1]}
      period={<div className="flex space-x-3 items-center justify-center">
        <TimerIcon width="21" height="21" />
        <div className="flex space-x-1 text-base">
                <span className="block text-[#6E6E6E]">
                  Данные
                </span>
          <span className="block text-black">
                  за 12 месяцев
                </span>
        </div>
      </div>}
      detailArray={detailArrayEnterprise}
      expandedKeys={expandedKeys}
      setExpandedKeys={setExpandedKeys}
      previusPrice={isActive !== monthsOne ? periodValues[monthsOne][1] : ""}
      description={computedRateItemDescription}
      externalContent={
        <div className="bg-[#FDF6EC] mt-3 p-6 rounded-lg border border-black/12">
          <div>
            <div className="flex text-[#7F694A] text-lg leading-[24px] font-bold justify-between items-center">
              <span className="block">Индивидуальное обучение</span>
              <LikeIcon width="24" height="24" />
            </div>
            <span className="block text-[#6E6E6E] text-xs leading-[13px] mt-2">
                  {`с менджером WeCheck`.toUpperCase()}
                </span>
          </div>
        </div>
      }
  />

  const extTariff = <RateItem
      id="ext"
      hotPrice
      title={<span style={{ whiteSpace: 'nowrap'}}>Расширение WB</span>}
      /*price={periodValues[isActive][2]}*/
      expandedKeys={expandedKeys}
      setExpandedKeys={setExpandedKeys}
      period={
        <div className="flex space-x-3 items-center justify-center">
          <WarningIcon width="21" height="21" />
          <div className="flex space-x-1 text-base">
                <span className="block text-[#6E6E6E]">
                  Данные
                </span>
            <span className="block text-black">
                  только на сайте WB
                </span>
          </div>
        </div>
      }
      detailArray={detailArrayExtWB}
      /*previusPrice={isActive !== monthsOne ? periodValues[monthsOne][2] : ""}*/
      /*description={computedRateItemDescription}*/
      externalContent={<div style={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        color: '#333D37',
        borderRadius: 4,
        width: '100%',
        paddingTop: 12,
        paddingBottom: 12,
        marginTop: 26
      }}>
        <span style={{display: 'block', fontSize: 16, paddingLeft: 23}}>Расширение работает в браузерах</span>
        <span style={{display: 'flex', fontSize: 16, alignItems: 'center' , paddingLeft: 23}}>
                <ChromeIcon style={{marginRight: 4}}/> Chrome и
                <YandexIcon style={{marginRight: 4, marginLeft: 4}}/> Yandex
              </span>
      </div>}
  />

  const handleScrollMobile = (e) => {
    const scrollLeft = e.target.scrollLeft;
    if (mobileActiveTariff !== 'pro' && scrollLeft >= 0 && scrollLeft <= 320) {
      setMobileActiveTariff('pro')
    } else if (mobileActiveTariff !== 'basic' && scrollLeft > 320 && scrollLeft <= 640) {
      setMobileActiveTariff('basic')
    } else if (mobileActiveTariff !== 'ext' && scrollLeft > 640) {
      setMobileActiveTariff('ext')
    }
  }

  return (
    <StyledSection id="rate_section" ref={rateRef}>
      <Container style={{padding:'0'}}>
        <StyledHeader >
          <StyledTitle>Наши тарифы</StyledTitle>
          <StyledButtonGroup>
            <RateButton
              name={monthsOne}
              handleClick={() => handleRatePeriod(monthsOne)}
              active={isActive === monthsOne}
            >
              Месяц
            </RateButton>
            <RateButton
              name={monthsThree}
              handleClick={() => handleRatePeriod(monthsThree)}
              sale="-15%"
              active={isActive === monthsThree}
            >
              3 месяца
            </RateButton>
            <RateButton
              name={monthsSix}
              handleClick={() => handleRatePeriod(monthsSix)}
              sale="-25%"
              active={isActive === monthsSix}
            >
              6 месяцев
            </RateButton>
          </StyledButtonGroup>
          <div className="lg:hidden flex space-x-2 p-4 w-full">
            <div className={`h-[80px] w-1/3 border rounded-lg py-2 px-3 
            ${mobileActiveTariff === 'pro' ? 'border-[#00745F] border-2 !text-[#00745F]' : 'border-[#C9C9C9]'}`}
                 onClick={() => setMobileActiveTariff('pro')}>
              <span className="block font-bold text-sm text-[#213A2B] mb-[23px]">
                Pro
              </span>
              <span className={`block text-base ${mobileActiveTariff === 'pro' ? 'text-[#213A2B]' : 'text-[#6B6C6E]'}`}>
                {periodValues[isActive][1]}
              </span>
            </div>
            <div className={`h-[80px] w-1/3 border rounded-lg py-2 px-3 
            ${mobileActiveTariff === 'basic' ? 'border-[#00745F] border-2 !text-[#00745F]' : 'border-[#C9C9C9]'}`}
                 onClick={() => setMobileActiveTariff('basic')}>
              <span className="block font-bold text-sm text-[#213A2B] mb-[23px]">
                Basic
              </span>
              <span className={`block text-base ${mobileActiveTariff === 'basic' ? 'text-[#213A2B]' : 'text-[#6B6C6E]'}`}>
                {periodValues[isActive][0]}
              </span>
            </div>
            <div className={`h-[80px] w-1/3 border rounded-lg py-2 px-3 
            ${mobileActiveTariff === 'ext' ? 'border-[#00745F] border-2 !text-[#00745F]' : 'border-[#C9C9C9]'}`}
              onClick={() => setMobileActiveTariff('ext')}>
              <span className="block font-bold text-sm text-[#213A2B] mb-[3px]">
                Расшире-<br />ние WB
              </span>
              <span className={`block text-base ${mobileActiveTariff === 'ext' ? 'text-[#213A2B]' : 'text-[#6B6C6E]'}`}>
                {periodValues[isActive][2]}
              </span>
            </div>
          </div>
        </StyledHeader>
        <StyledRateBlock ref={swipeRef} className="space-x-3 px-4 overflow-x-auto lg:!hidden !pb-8"
                         onScroll={handleScrollMobile}>
          {proTariff}
          {basicTariff}
          {extTariff}
        </StyledRateBlock>
        <StyledRateBlock className="!hidden lg:!flex">
          {basicTariff}
          {proTariff}
          {extTariff}
        </StyledRateBlock>
      </Container>
    </StyledSection>
  );
};
